.home {
  font-feature-settings: normal;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}
.privacyDisclaimerContainer {
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
}
.privacyDisclaimerText {
  font-size: 1rem;
  color: black;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.propertyInfoPage {
  background-color: #fff8ef;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: white;
  overflow: scroll;
}
.propertyInfoTopRow {
  height: 85%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.firstPage {
  display: flex;

  align-items: center;
  justify-content: center;
}
.propertyInfoContentContainer {
  background-color: #f7f0e6;
  height: 75%;

  /* margin-top: 5%;
  margin-bottom: 5%; */
  display: flex;
  flex-direction: column;
  width: 75%;
}
.propertyInfoContentContainerFirstPage {
  background-color: #f7f0e6;
  height: 75%;
  /* margin-top: 5%;
  margin-bottom: 5%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
}
.propertyInfoContentContainer2 {
  background-color: #f7f0e6;
  height: 70%;
  margin-top: 3.75%;
  margin-bottom: 3.75%;
  display: flex;
  flex-direction: column;
  width: 70%;
}
.firstPageRow {
  display: flex;

  width: 86%;
  margin: 10px auto 0 auto;
  justify-content: space-around;
}

.firstCol {
  width: 50%;
}
.firstCol:first-child {
  height: 80%;
}
.firstHomeownerNames {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.firstHomeownerNames div {
  font-family: 'playfair-display', serif;
  font-weight: 400;
  font-style: italic;
  /* font-size: 30px; */
  font-size: 3rem;

  color: #00305e;
  /* text-align: center; */
}
.homeownerFirstIndex {
  color: #3ee1e2;
}
.homeownerSecondIndex {
  color: #41e432;
}
.homeownerThirdIndex {
  color: #bc79ff;
}
.homeownerFourthIndex {
  color: #ff6b00;
}

.firstAddress {
  /* margin-top: 20px; */
  display: flex;

  /* letter-spacing: 1px; */
}
.firstAddress div {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16.5px;
  margin-right: 5px;
  margin-top: 1rem;
  color: black;
  text-transform: capitalize;
}
.firstAddress .firstStreetName {
  margin-right: 15px;
}
.firstAddress .thirdStreetName {
  margin-right: 15px;
}
.firstDescriptionContainer {
  width: 73%;
  margin: 40px auto 0 auto;
}
.firstDescriptionContainer p {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 17px;
  line-height: 31.5px;

  color: black;
  text-align: justify;
}
.firstAgentName {
  width: 71%;

  display: flex;
  justify-content: flex-end;
  margin: auto;
}
.firstAgentName p {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 19px;
  color: black;
  text-align: justify;
}
.smallerFirstAgentName p {
  font-size:18px;
}
.firstCoverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstCoverImg {
  height: 380px;
  width: auto;
}
.valuationButtonBorder {
  border-radius: 50px;
  width: 354px;
  height: 59px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 38px auto 0 auto;
}
.valuationButtonBorder:hover {
  cursor: pointer;
  background-color: gray;
}
.valuationButton {
  background-color: white;
  border-radius: 50px;
  width: 314px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cac6bc;
}
.valuationButtonText {
  color: black;
  text-align: center;

  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}

.agentInfoFooterContainer {
  background-color: #00305e;
  width: 100%;
  height: 15%;
}
.agentInfoFooter {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.agentInfoFooterCol1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}
.agentInfoPhoneAndNameRow {
  display: flex;
}
.agentInfoFooterAgentName {
  margin-right: 2rem;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.4rem;
  color: white;
}
.agentInfoAgentPhone {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  color: white;
}
.agentInfoDesignationAndDreRow {
  display: flex;
  margin-top: 0.5rem;
  color: white;
}
.agentDesignation {
  margin-right: 2rem;
  text-transform: uppercase;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.3rem;
  letter-spacing: 0.075em;
  color: white;
}
.agentInfoDre {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}
.agentInfoFooterCol2 {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.agentInfoLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 35rem;
}
.agentInfoLogo {
  height: 8rem;

  width: auto;
}

.agentInfoFooterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  background-color: #00305e;
  width: 100%;
  height: 15%;
}
.footerLGBlue {
  background-color: #00305e;
}
.footerBlack {
  background-color: black;
}
.footerPurple {
  background-color: #542248;
}
.footerHarbor {
  background-color: #133e45;
}
.footerLightBlue {
  background-color: #0179c1;
}
.agentInfoFooterContainer .douglas-cover-info {
  height: 135px;
  margin-top: -20px;
}
.scar-logo,
.uhrig-logo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.scar-logo,
.uhrig-logo img {
  max-width: 90px;
  width: auto;
  height: auto;
  margin-bottom: 26px;
}

.uhrig-logo img {
  height: 79px;
  max-width: 100px;
  width: auto;
  margin-bottom: 23px;
}
.johnsons-qr-code {
  width: 104px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -17px;
  margin-top: -3px;
  margin-left: 0px;
}
.johnsons-qr-code img {
  height: 69px;
  max-width: 100px;
  width: auto;
  margin-bottom: 23px;
}
.kristin-arntz-cover-logo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-left: -10px;
}
.kristin-arntz-cover-logo img {
  max-width: 80px;
  width: auto;
  height: auto;
  margin-bottom: 26px;
}
.david-kim-cover-logo {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -50px;
  margin-left: 20px;
}
.david-kim-cover-logo img {
  max-width: 50px;
  width: auto;
  height: auto;
}
.calgano-hamilton-cover-logo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: -6px;
}
.calgano-hamilton-cover-logo img {
  max-width: 90px;
  width: auto;
  height: auto;
}
.global-luxury-cb-cover-logo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.global-luxury-cb-cover-logo img {
  max-height: 70px;
  max-width: 70px;
  width: auto;
  height: auto;
}
.qv4CoverPageInfo {
  width: 100%;
}
.qv4CoverPageInfo .transparentInnerContainer {
  display: flex;
  margin: auto;
  width: 80%;
  height: 80px;
}
.qv4CoverPageInfo .threePersonInnerContainer {
  display: flex;
  margin: auto;
  width: 92%;
  height: 90px;

  justify-content: space-between;
}
.qv4CoverPageInfo .threePersonInnerContainer .agentInfoSection {
  width: 90%;
  justify-content: space-around;
}
.qv4CoverPageInfo .threePersonInnerContainer .agentInfoSection .teamInfoCol {
  width: 59%;
}
.customWebsiteLink {
  color: white;
}
.privacyPolicy {
  display: flex;
  align-items: center;
  height: 90%;
}
.privacyPolicyCol {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.privacyPolicy p {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.privacyPolicy:hover {
  cursor: pointer;
}
.qv4CoverPageInfo .phoneNumRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qv4CoverPageInfo .phoneCircleDivider {
  background-color: white;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
}
.qv4CoverPageInfo .agent-info-presented-by {
  width: 546px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.qv4CoverPageInfo .agent-info-presented-by.three-agent-presented {
  margin-bottom: 47px;
  margin-top: -20px;
}
.qv4CoverPageInfo .agentInfoSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  height: 100%;
}
.agentInfoSection.team {
  height: 100%;
}
.agentInfoCol {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.qv4CoverPageInfo .agentInfoSection .agentInfoCol div {
  display: flex;
}
.agentAndPhone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.agentAndPhone .agentName,
.agentAndPhone .agentPhone,
.agentAndPhone .phoneNumRow {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  color: white;
}
.agentName.smallerNameAndPhone,
.agentPhone.smallerNameAndPhone,
.phoneNumRow.smallerNameAndPhone,
.agentName.small,
.agentPhone.small,
.phoneNumRow.small {
  font-size: 17px;
}
.verticalDivider {
  padding: 0 7px;
}
.designationAndDre {
  color: white;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.designationAndDre .agentDesignation {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12.5px;
  text-transform: uppercase;
  margin-right: 15px;
  letter-spacing: 0.075em;
}
.dreWord,
.dreNum {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}
.special-single-agent-under-name-text {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}
.teamInfoCol {
  margin: 0em 0 0em 0;
  width: 74.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.zechInfoFooterRow {
  margin: 0em 0 0em 0;
  width: 74.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.zechInfoFooterRow .phoneNumRow {
  justify-content: flex-start;
}
.team-agent-separator {
  height: 1px;
  background-color: white;
  width: 200px;
  margin: auto;
}
.teamAgentContainer:nth-child(2) {
  margin-top: 0px;
}
.teamAgentContainer {
  display: flex;
  justify-content: flex-start;
}
.teamAgentContainer.smallerHeight {
  height: 53px;
}
.teamAgentCol {
  width: auto;
}
.teamSubInfo {
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  height: 24px;
  justify-content: center;
}
.qv4CoverPageInfo
  .agentInfoSection
  .teamInfoCol
  .teamAgentContainer
  .teamAgentCol
  .teamSubInfo.reducedTeamSubInfoHeight {
  height: 22px;
}

.teamAgentAndPhone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
  color: white;
}
.phoneCircleDivider {
  background-color: white;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
}
.teamAgentName {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
}
.teamAgentName.largerAgentName {
  font-size: 20px;
}
.teamAgentPhone {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
}
.teamAgentPhone.largerPhoneNum {
  font-size: 21px;
}
.smallerNameAndPhone {
  font-size: 16px;
}
.teamVerticalDivider {
  background-color: white;
  margin: 0 7px;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.teamDesignationAndDre {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}
.designationText {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 15px;
  letter-spacing: 0.075em;
}
.teamDesignationAndDre .dreRow {
  display: flex;
  align-items: flex-end;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  text-transform: uppercase;
}
.dreWord {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.075em;
}
.dreWord.larger-dreWord {
  font-size: 13px;
}
.dreRow .dreNum {
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 0.075em;
}
.dreNum.larger-dreNum {
  font-size: 13px;
}
.team-email {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9px;
  color: white;
}
.qv4CoverPageInfo .agentInfoBoxLogo {
  height: 81px;
  /* width: auto; */
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
}

.agentInfoBoxLogo.longerAgentInfoBoxLogo {
  width: 45%;
}
.agentInfoBoxLogo.agentLogoMr {
  margin-right: 40px;
}
.agentInfoBoxLogo.centered-box-logo {
  justify-content: center;
}
.agentInfoBoxLogo img {
  height: 80%;
  max-height: 80%;
  width: auto;

  max-width: 100%;
}

.qv4CoverPageInfo .agentInfoBoxLogo .Horizontal_White_Compass {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 80%;
}

.teamLogo {
  height: auto;
  width: auto;
}
.cb-stacked-brokerage-logo {
  max-height: 135%;
}
.cb-gay-dales-brokerage-logo {
  max-height: 95%;
}
.cb-luxury-brokerage-logo {
  height: 70px;
  width: 70px;
  margin-left: -30px;
  justify-content: center;
}
.village-properties-logo2 {
  max-width: 94%;
  margin-right: 19px;
}
.long-bhhs-logo {
  max-height: 120%;
  margin-top: -27px;
  max-width: 130%;
}
.long-bhhs-logo.teamLogo {
  margin-top: 104px;
}
.long-bhhs-logo.douglasSpecializedLogo {
  margin-top: 47px;
  margin-right: -35px;
}
.bhhsStackedCoverLogo {
  max-height: 92px;
  /* margin-top: -38px; */
  max-width: 116px;
}
.bhhsStackedCoverLogo.teamLogo {
  margin-top: 14px;
}
.Horizontal_White_Compass.teamLogo {
  height: auto;
  width: auto;
  /* margin-bottom: -110px; */
  max-width: 265px;
  /* max-height: 152px; */
}

.berkshire-hathaway-long-brokerage-logo {
  max-height: 110%;
  margin-top: -7px;
  max-width: 120%;
}
.berkshire-hathaway-long-brokerage-logo.teamLogo {
  margin-top: 75px;
}
.pres_circle_bronze_500x500px {
  max-width: 100%;
  max-height: 100%;
}
.luxury-berkshire-logo {
  max-height: 120%;
  margin-top: -37px;
  max-width: 130%;
}
.luxury-berkshire-logo.teamLogo {
  margin-top: 75px;
}
.logo_cbgl_white_EDIT_2_021523-01-1 {
  max-height: 98%;
  max-width: 113%;
  margin-bottom: 16px;
}
.BHHS-LUXcollection-White_500px300ppi {
  max-height: 159%;
  max-width: 118%;
  margin-right: -21px;
}
.above-beyond-transparent-white {
  max-width: 130%;
}
.over-the-moon-all-white-logo,
.overTheMoonAllWhiteLogo {
  max-height: 90%;
  max-width: 120%;
}
.agentInfoSection {
  height: 48px;
}
.teamAgentName {
  font-size: 26px;
}
/* .dreRow {
  margin-top: 4px;
} */

.qv4CoverPageInfo.coverInfoThreeTeam {
  height: 180px;
}
.scarLogo,
.uhrigLogo {
  width: 130px;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.scarLogo img {
  height: 85px;
  max-width: 100px;
  width: auto;
}
.uhrigLogo img {
  height: 79px;
  max-width: 100px;
  width: auto;
  margin-bottom: 23px;
}
.johnsonsQrCode {
  width: 104px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -27px;
  margin-top: 20px;
  margin-left: 5px;
}
.johnsonsQrCode img {
  height: 79px;
  max-width: 100px;
  width: auto;
  margin-bottom: 23px;
}
.kristinArntzCoverLogo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
  margin-left: -10px;
}
.kristinArntzCoverLogo img {
  max-width: 90px;
  width: auto;
  height: 91px;
}
.davidKimCoverLogo {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -50px;
  margin-left: 20px;
}

.davidKimCoverLogo img {
  max-width: 50px;
  width: auto;
  height: auto;
}
.calganoHamiltonCoverLogo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
}

.calganoHamiltonCoverLogo img {
  max-width: 90px;
  width: auto;
  height: auto;
}

.globalLuxuryCbCoverLogo {
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.globalLuxuryCbCoverLogo img {
  max-height: 70px;
  max-width: 70px;
  width: auto;
  height: auto;
}

.vaitCoverInfo {
  width: 73%;
  margin-top: -10px;
  height: 100%;
}
.vaitCoverInfo .vaitTransparentInnerContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 95%;
  margin-top: 0.8rem;
}
.vaitCoverInfo .vaitAgentInfoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.vaitCoverInfo .vaitAgentInfoSection.team {
  height: 104px;
}
.vaitCoverInfo .vaitAgentInfoSection .vaitAgentInfoCol {
  width: 100%;
  height: 100%;
  margin-bottom: -5px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.vaitCoverInfo .vaitAgentInfoSection .vaitAgentInfoCol div {
  display: flex;
}
.vaitCoverInfo .vaitAgentInfoSection .vaitAgentInfoCol .vaitAgentAndPhone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.vaitCoverInfo .vaitAgentInfoSection .vaitAgentInfoCol .vaitDesignationAndDre {
  color: white;
  display: flex;
  align-items: center;
}
.vaitCoverInfo .vaitAgentInfoSection .bhhsStackedCoverLogo {
  height: 80px;
  width: auto;
  max-height: 81px;
  max-width: 155px;
}

.vaitCoverInfo .vaitAgentName {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 19px;
  text-transform: uppercase;
  color: white;
}
.vaitCoverInfo .vaitDesignation {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 19px;
  color: white;
}
.vaitCoverInfo .vaitDesignation.caps {
  text-transform: uppercase;
}
.vaitCoverInfo .vaitDesignationSpan {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 19px;
  color: white;
  margin: 0 3px;
}
.vaitCoverInfo .vaitDesignationAndDre {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  /* margin-top: 9px; */
  margin-left: 10px;
}
.vaitCoverInfo .vaitAgentPhoneNumber {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: white;
}
.vaitCoverInfo .vaitAgentPhoneNumber .phoneNumRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vaitCoverInfo .vaitAgentPhoneNumber .phoneCircleDivider {
  background-color: white;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
}
.vaitCoverInfo .vaitAgentInfoBoxLogo {
  height: 85px;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.vaitCoverInfo .vaitAgentInfoBoxLogo img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 80%;
}
.vaitCoverInfo .bhhsStackedCoverLogo {
  height: 80px;
  width: auto;
  max-height: 122px;
  max-width: 155px;
}

.vaitCoverLogo {
  width: 140px;
  height: auto;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vaitCoverLogo img {
  width: 190px;
  height: auto;
  margin: auto;
}

.bhhsStackedCoverLogo {
  /* height: 90px; */
  width: auto;
  height: auto;
  max-height: 92px;
  max-width: 127px;
}
.vaitDrePhoneRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pageTrackerContainer {
  /* margin-top: 14.1rem; */
  margin-top: -3.1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageTrackerContainer2 {
  margin-top: -2.8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageTracker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 600px;
}
.activeTracker {
  border-radius: 50px;
  width: 54px;
  height: 54px;
  border: 1px solid #b0aa9b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageTrackerNum {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 10px;
  background: #f7f0e6;
  font-size: 21px;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 1px solid #cac6bc;
}
.pageTrackerNum:hover {
  cursor: pointer;
}
.activeTracker .pageTrackerNum {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 10px;
  background: #fff;
  font-size: 21px;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: objektiv-mk2, sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 1px solid #cac6bc;
}
.pageTrackerDivider {
  height: 1px;
  width: 235px;
  background-color: #b0aa9b;
}

.secondPageCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.secondPageTitle {
  font-family: 'playfair-display', serif;
  font-weight: 400;
  font-style: italic;
  font-size: 2.8rem;
  margin-top: 1rem;
  color: #00305e;
}
.secondPageAddress {
  color: black;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.625rem;
}

.secondAddress {
  display: flex;
}
.secondAddress div {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16.5px;
  margin-right: 5px;
  margin-top: 1rem;
  color: black;
  text-transform: capitalize;
}
.secondAddress .secondStreetName {
  margin-right: 15px;
}
.secondAddress .stateText {
  text-transform: uppercase;
}

.secondPageDetails {
  margin-top: 1.3rem;
  margin-bottom: 0.5rem;
}
.secondPageDetails div {
  color: black;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
}
.propDetailsContainer {
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin-top: 0px;
}
.propDetailsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  /* border-radius: 50%; */
}
.propDetailsBox:hover .propDetailsCircle:hover {
  cursor: pointer;
  background-color: #e5dfd7;
  height: 110px;
  width: 110px;
}
.propDetailsCircle {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff8ef;
}
.propDetailsCircle:hover {
  cursor: pointer;
}
.bubbleInput {
  border: none;
  text-align: center;
  background-color: transparent;
  border-bottom: none;
  color: black;
  font-family: 'playfair-display', serif;
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  /* margin-top: -11px; */
}
.bubbleInput:focus {
  outline: none;
}
.bubbleInput:hover {
  cursor: pointer;
}
.bubbleSelect {
  color: black;
  width: 70px;
  height: 100px;
  border-bottom: none;
  border: 0;
  text-align: center;
  background: transparent;
  font-family: 'playfair-display', serif;
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  /* margin-top: -11px; */
}
.bubbleSelect:focus {
  outline: none;
}
.bubbleSelect:hover {
  cursor: pointer;
}
.propCircle1 {
  border: 2px solid #67ffff;
}
.propCircle2 {
  border: 2px solid #67ff4d;
}
.propCircle3 {
  border: 2px solid #bc79ff;
}
.propCircle3:hover {
  cursor: pointer;
}
.propCircle4 {
  border: 2px solid #ff6b00;
}
.propDetailsText {
  font-family: 'playfair-display', serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  /* margin-top: -11px; */
  color: black;
}

.propDetailsTitle {
  font-family: 'playfair-display', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  color: black;
  margin-top: 5px;
}
.commentsInputContainer {
  margin-top: 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.commentsInput {
  outline: 0;
  border-width: 0 0 1px;
  border-color: #cac6bc;
  background-color: transparent;
  width: 95%;
  margin: 0px auto 0 auto;
  color: black;
  font-size: 2.2rem;
  height: 30px;
}
.commentsInput:focus {
  border-color: #cac6bc;
}
.commentsInput::placeholder {
  color: gray;
  font-size: 2.2rem;
}
.additionalInfoText {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.6rem;
  margin-top: 3.5rem;
  margin-bottom: 0.5rem;
  color: black;
}
.secondPageSubmitBtn {
  margin-top: 1.1rem;
  margin-bottom: 0.5rem;
  border-radius: 5rem;
  width: 39.4rem;
  height: 6.4rem;
  border: 1px solid #b0aa9b;

  display: flex;
  justify-content: center;
  align-items: center;
}
.secondPageSubmitBtn:hover {
  background-color: gray;
}
.secondPageValuationButton {
  background-color: white;
  border-radius: 5rem;
  width: 36.8rem;
  height: 4rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cac6bc;
}
.secondPageValuationButton:hover {
  cursor: pointer;
}
.submitValuationButtonText {
  color: black;
  text-align: center;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.6rem;
}
.formError {
  margin-top: 5px;
  width: 100%;

  height: 14px;
}
.formError p {
  font-family: 'playfair-display', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgb(154, 20, 20);
  text-align: center;
}

.thirdPageCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thirdAddress {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7rem;
  letter-spacing: 1px;
  margin-top: 1rem;
  display: flex;
  color: black;
}

.thirdAddress div {
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: black;
  text-transform: capitalize;
}
.thirdTitle {
  margin-top: 2.5rem;
  font-family: 'playfair-display', serif;
  font-weight: 400;
  font-style: italic;
  font-size: 3.4rem;
  color: #00305e;
}
.thirdFormContainer {
  max-width: 80rem;
  padding: 0 2.5rem;
  margin: auto;
}
.fieldHolder {
  position: relative;
}
.thirdInput {
  border: 0;
  border-bottom: 1px solid #cac6bc;
  outline: none;
  font-size: 1.5rem;
}
.thirdLabel {
  position: absolute;
  top: 0;
  left: 0;
  color: black;
  display: flex;
  align-items: center;
  cursor: text;
  transition: 0.3s ease-in-out;
}

.thirdInput,
.thirdLabel {
  width: 100%;
  height: 7.5rem;
  font-size: 1.5rem;
}
.thirdInput:focus + label,
.thirdInput:valid + label {
  color: black;
  top: -2.5rem;
  pointer-events: none;
}
.thirdInput:focus,
.thirdInput:valid {
  border-bottom: 2px solid #cac6bc;
}

.thirdContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.thirdForm {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.thirdInput {
  outline: 0;
  border-width: 0 0 1px;
  border-color: #cac6bc;
  background-color: transparent;
  width: 50rem;
  color: black;
  height: 3rem;
  margin-top: 3rem;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.9rem;
}
.thirdInput::placeholder {
  color: white;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f7f0e6 inset;
  color: black;
}
input::selection,
div::selection {
  background: gray;
  color: black;
}

.thirdSubmitButtonBorder {
  margin-top: 4.5rem;
  border-radius: 5rem;
  width: 384px;
  height: 7.4rem;
  border: 1px solid #b0aa9b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thirdSubmitButtonBorder:hover {
  cursor: pointer;
}
.thirdSubmitButtonBorder:hover {
  background-color: gray;
}
.thirdSubmitButton {
  background-color: white;
  border-radius: 50px;
  width: 358px;
  height: 4.4rem;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cac6bc;
}
.thirdSubmitButtonText {
  color: black;
  text-align: center;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
}
.fourthPage {
  display: flex;
  flex-direction: column;
}

.fourthRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  align-items: flex-start;
  justify-content: space-between;
}
.fourthCol1 {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fourthCol2 {
  height: 100%;
  width: 48%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.fourthCol2 img {
  height: 90%;
  width: auto;
}
.fourthTitle {
  color: white;
  font-family: 'playfair-display', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.3rem;
  /* color: black; */
  color: #00305e;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 3.5rem;
  /* margin-top: -50px; */
}

.headerLGBlue {
  color: #00305e;
}
.headerBlack {
  color: black;
}
.headerPurple {
  color: #542248;
}
.headerHarbor {
  color: #133e45;
}
.headerLightBlue {
  color: #0179c1;
}

.fourthDescriptionContainer {
  width: 70%;
  margin: 1rem auto 0 auto;
}
.fourthParagraphSection {
  padding-top: 0.1rem;
}
.fourthAgentName {
  font-size: 2rem;
  color: black;
  text-align: right;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.fourthDescriptionText {
  color: white;
  font-family: 'objektiv-mk2', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.7rem;
  color: black;
  line-height: 2.2rem;
  margin-top: 15px;
  /* line-height: 2.2rem; */
}

.numberDropDown option {
  background-color: white;
}
.numberDropDown:hover {
  cursor: pointer;
}
.numberDropDown option:hover {
  cursor: pointer;
}
.modalButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 300px;
}
.modalButton {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalButton:hover {
  cursor: pointer;
}
.modalCancelButton {
  border-radius: 10px;
  border: 1px solid gray;
}
.modalSubmitButton {
  border-radius: 10px;
  border: 1px solid gray;
  background-color: lightblue;
}
/* Smartphones (portrait and landscape) ----------- */
/* @media only screen and (min-device-width: 320px) and (max-device-width: 480px) { */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  /* Styles */

  .propertyInfoPage {
    width: 100%;
    height: 100%;
    color: white;
    overflow: scroll;
    background-color: #fff8ef;
  }
  .propertyInfoContentContainerFirstPage {
    flex-direction: column;
    width: 100%;
  }
  .threePersonInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .team .transparentInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
  }
  .teamInfoCol {
    margin-top: 20px;
  }
  .teamAgentCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    width: 100%;
  }
  .teamAgentAndPhone {
    display: flex;
    flex-direction: column;
    height: 71px;
  }
  .teamDesignationAndDre {
    display: flex;
    flex-direction: column;
  }
  .teamSubInfo {
    margin-top: 0px;
    height: 44px;
  }
  .firstPageRow {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
  }
  .firstPage {
    display: flex;
    flex-direction: column;
  }

  .firstCol {
    width: 100%;
  }

  .firstHomeownerNames div {
    font-size: 2.7rem;
  }

  .firstDescriptionContainer {
    width: 75%;
    margin: 20px auto 0 auto;
  }
  .firstDescriptionContainer p {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  .firstAgentName {
    width: 71%;
  }
  .firstAgentName p {
    font-size: 2.2rem;
    color: black;
    text-align: justify;
    font-family: 'objektiv-mk2', sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  .firstCoverContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }
  .firstCoverImg {
    height: 380px;
    width: auto;
  }
  .valuationButtonBorder {
    margin: 38px auto 3rem auto;
  }
  .secondPageCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .secondPageTitle {
    font-family: 'playfair-display', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 2.3rem;
    margin-top: 1rem;
    color: #00305e;
  }
  /* .secondPageAddress {
          color: black;
          margin-top: 3rem;
          margin-bottom: 2rem;
          font-family: 'objektiv-mk2', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 0.1625rem;
        } */
  /* .secondPageDetails {
          margin-top: 1.3rem;
        } */
  .secondPageDetails {
    padding: 0 1.5rem;
  }
  /* .secondPageDetails div {
          padding: 0 1rem;
        } */
  .propDetailsContainer {
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .propDetailsBox {
    /* display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; */
    width: 17rem;
    height: 17rem;
    margin-top: 2.5rem;
    /* border-radius: 50%; */
  }
  .propDetailsBox:hover .propDetailsCircle:hover {
    cursor: pointer;
    background-color: #e5dfd7;
    height: 110px;
    width: 110px;
  }
  .propDetailsCircle {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff8ef;
  }
  .propDetailsCircle:hover {
    cursor: pointer;
  }
  .bubbleInput {
    border: none;
    text-align: center;
    background-color: transparent;
    border-bottom: none;
    color: black;
  }
  .bubbleInput:focus {
    outline: none;
  }
  .bubbleInput:hover {
    cursor: pointer;
  }
  .bubbleSelect {
    color: black;
    width: 100px;
    height: 100px;
    border-bottom: none;
    border: 0;
    text-align: center;
    background: transparent;
  }
  .bubbleSelect:focus {
    outline: none;
  }
  .bubbleSelect:hover {
    cursor: pointer;
  }
  .propCircle1 {
    border: 2px solid #67ffff;
  }
  .propCircle2 {
    border: 2px solid #67ff4d;
  }
  .propCircle3 {
    border: 2px solid #bc79ff;
  }
  .propCircle3:hover {
    cursor: pointer;
  }
  .propCircle4 {
    border: 2px solid #ff6b00;
  }
  .propDetailsText {
    font-family: 'playfair-display', serif;
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    /* margin-top: -11px; */
    color: black;
  }

  .propDetailsTitle {
    font-family: 'playfair-display', serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    color: black;
    margin-top: 8px;
  }

  .commentsInputContainer {
    width: 90%;

    margin: auto;
  }
  .commentsInput {
    width: 90%;

    font-size: 2.4rem;
    margin: 40px 0 40px 0;
  }

  .additionalInfoText {
    text-align: center;
    width: 80%;
    margin: auto;
  }

  .thirdAddress {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 20px;
  }
  .fieldHolder {
    position: relative;
  }
  .thirdInput {
    border: 0;
    border-bottom: 1px solid #cac6bc;
    outline: none;
    font-size: 1.5rem;
  }
  .thirdLabel {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
    display: flex;
    align-items: center;
    cursor: text;
    transition: 0.3s ease-in-out;
  }

  .thirdInput,
  .thirdLabel {
    width: 100%;
    /* height: 75px; */
    height: 4.7rem;
  }

  .thirdContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .thirdInput {
    width: 20rem;
  }

  .thirdInput:focus + label,
  .thirdInput:valid + label {
    color: black;
    font-size: 1.5rem;
    top: -1.3rem;
    pointer-events: none;
  }
  .thirdTitle {
    font-size: 2.4rem;
    padding: 0 6rem;
    text-align: center;
  }
  .thirdSubmitButtonBorder {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    width: 27rem;
  }
  .thirdSubmitButton {
    width: 25rem;
    margin: 0rem 0;
  }

  .propertyInfoContentContainer {
    height: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .fourthRow {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .fourthCol1 {
    width: 100%;
    height: 57rem;
  }

  .fourthCol2 {
    display: none;
  }
  .fourthCol2 img {
    height: 50rem;
    width: auto;
    margin: auto;
  }
  .fourthTitle {
    font-size: 2.5rem;
    color: #00305e;
    margin-bottom: 1.5rem;
    text-align: center;
    /* margin-top: -50px; */
  }
  .fourthDescriptionContainer {
    width: 70%;
    margin: 1.5rem auto 0 auto;
  }
  .fourthParagraphSection {
    padding-top: 0.1rem;
  }

  .fourthDescriptionText {
    font-size: 1.3rem;
    color: black;
    line-height: 2.2rem;
  }

  .agentInfoFooterContainer {
    /* background-color: #00305e; */
    width: 100%;
    height: 100%;
  }

  .qv4CoverPageInfo {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .agentInfoFooterCol1 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 21px;
    border-bottom: 1px solid white;
  }

  .agentInfoFooterAgentName {
    font-size: 2.4rem;
    color: white;
  }
  .agentAndPhone {
    justify-content: space-between;
  }

  .agentInfoAgentPhone {
    font-size: 2.2rem;
  }
  .agentInfoDesignationAndDreRow {
    justify-content: space-between;
  }
  .agentDesignation {
    font-size: 1.3rem;
  }
  .agentInfoFooterCol2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    justify-content: center;
  }

  .secondPageSubmitBtn {
    width: 24.4rem;
  }
  .secondPageValuationButton {
    width: 22.8rem;
  }

  .submitValuationButtonText {
    font-size: 1.1rem;
  }

  .pageTrackerContainer {
    margin-top: 0rem;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pageTrackerContainer2 {
    margin-top: 0.8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pageTracker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }
  .activeTracker {
    border-radius: 50px;
    width: 75px;
    width: 55px;
    border: 1px solid #b0aa9b;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pageTrackerNum {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 10px;
    background: #f7f0e6;
    font-size: 21px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid #cac6bc;
  }

  .activeTracker .pageTrackerNum {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 10px;
    background: #fff;
    font-size: 21px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid #cac6bc;
  }
  .pageTrackerDivider {
    height: 1px;
    width: 6rem;
    background-color: #b0aa9b;
  }
  .agentInfoFooterContainer {
    height: 100%;

    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .privacyPolicy {
    margin-bottom: 10px;
  }

  .agentInfoFooterContainer .transparentInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    width: 100%;
  }

  .agentInfoFooterContainer .qv4CoverPageInfo {
    width: 100%;
  }
  .agentInfoFooterContainer .agentInfoSection {
    height: 100%;
    width: 100%;

    flex-direction: column;
  }
  .agentInfoFooterContainer .agentAndPhone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 29px;
  }

  .agentInfoFooterContainer .agentName {
    font-size: 26px;
  }
  .agentInfoFooterContainer .phoneNumRow {
    font-size: 29px;
  }
  .zechInfoFooterRow {
    flex-direction: column;
  }
  .zechInfoFooterRow .teamAgentName {
    font-size: 2.8rem;
  }
  .zechInfoFooterRow .phoneNumRow {
    font-size: 1.8rem;
  }
  .zechInfoFooterRow .teamAgentContainer {
    justify-content: center;
  }
  .zechInfoFooterRow .teamAgentAndPhone {
    height: 35px;
  }
  .agentInfoFooterContainer .designationAndDre {
    width: 30rem;

    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .agentInfoFooterContainer .dreWord,
  .agentInfoFooterContainer .dreNum {
    font-size: 17.5px;
  }
  .agentInfoFooterContainer .agentDesignation {
    font-size: 17.5px;
  }
  .agentInfoFooterContainer .agentInfoCol {
    display: flex;
    flex-direction: column;
  }
  .agentInfoFooterContainer .agentInfoBoxLogo {
    margin-top: 10px;
    padding-top: 10px;
    width: 100%;
    height: 121px;
    justify-content: center;
    border-top: 1px solid white;
  }
  .agentInfoFooterContainer .agentInfoBoxLogo img {
    max-height: 100%;
  }

  .agentInfoBoxLogo.agentLogoMr {
    margin-right: 0px;
  }
  .qv4CoverPageInfo .agentInfoBoxLogo .Horizontal_White_Compass {
    margin-top: 20px;
    height: auto;
    width: auto;
    max-height: 85%;
    max-width: 55%;
  }
  .privacyDisclaimerContainer {
    margin-bottom: 1.5rem;
  }
  .privacyDisclaimerText {
    font-size: 1.2rem;
    padding: 0 10px;
    text-align: center;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .propertyInfoPage {
    width: 100%;
    height: 100%;
    color: white;
    overflow: scroll;
    background-color: #fff8ef;
  }
  .propertyInfoContentContainerFirstPage {
    flex-direction: column;
    width: 100%;
  }

  .team .transparentInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
  }
  .teamInfoCol {
    margin-top: 20px;
  }
  .teamAgentCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    width: 100%;
  }
  .teamAgentAndPhone {
    display: flex;
    flex-direction: column;
    height: 71px;
  }
  .teamDesignationAndDre {
    display: flex;
    flex-direction: column;
  }
  .teamSubInfo {
    margin-top: 0px;
    height: 44px;
  }
  .firstPageRow {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
  }
  .firstPage {
    display: flex;
    flex-direction: column;
  }

  .firstCol {
    width: 100%;
  }

  .firstHomeownerNames div {
    font-size: 2.7rem;
  }

  .firstDescriptionContainer {
    width: 75%;
    margin: 20px auto 0 auto;
  }
  .firstDescriptionContainer p {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  .firstAgentName {
    width: 71%;
  }
  .firstAgentName p {
    font-size: 2.2rem;
    color: black;
    text-align: justify;
    font-family: 'objektiv-mk2', sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  .firstCoverContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }
  .firstCoverImg {
    height: 380px;
    width: auto;
  }
  .valuationButtonBorder {
    margin: 38px auto 3rem auto;
  }
  .secondPageCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .secondPageTitle {
    font-family: 'playfair-display', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 2.3rem;
    margin-top: 1rem;
    color: #00305e;
  }
  /* .secondPageAddress {
          color: black;
          margin-top: 3rem;
          margin-bottom: 2rem;
          font-family: 'objektiv-mk2', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 0.1625rem;
        } */
  /* .secondPageDetails {
          margin-top: 1.3rem;
        } */
  .secondPageDetails {
    padding: 0 1.5rem;
  }
  /* .secondPageDetails div {
          padding: 0 1rem;
        } */
  .propDetailsContainer {
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .propDetailsBox {
    /* display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; */
    width: 17rem;
    height: 17rem;
    margin-top: 2.5rem;
    /* border-radius: 50%; */
  }
  .propDetailsBox:hover .propDetailsCircle:hover {
    cursor: pointer;
    background-color: #e5dfd7;
    height: 110px;
    width: 110px;
  }
  .propDetailsCircle {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff8ef;
  }
  .propDetailsCircle:hover {
    cursor: pointer;
  }
  .bubbleInput {
    border: none;
    text-align: center;
    background-color: transparent;
    border-bottom: none;
    color: black;
  }
  .bubbleInput:focus {
    outline: none;
  }
  .bubbleInput:hover {
    cursor: pointer;
  }
  .bubbleSelect {
    color: black;
    width: 100px;
    height: 100px;
    border-bottom: none;
    border: 0;
    text-align: center;
    background: transparent;
  }
  .bubbleSelect:focus {
    outline: none;
  }
  .bubbleSelect:hover {
    cursor: pointer;
  }
  .propCircle1 {
    border: 2px solid #67ffff;
  }
  .propCircle2 {
    border: 2px solid #67ff4d;
  }
  .propCircle3 {
    border: 2px solid #bc79ff;
  }
  .propCircle3:hover {
    cursor: pointer;
  }
  .propCircle4 {
    border: 2px solid #ff6b00;
  }
  .propDetailsText {
    font-family: 'playfair-display', serif;
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    /* margin-top: -11px; */
    color: black;
  }

  .propDetailsTitle {
    font-family: 'playfair-display', serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    color: black;
    margin-top: 8px;
  }

  .commentsInputContainer {
    width: 90%;

    margin: auto;
  }
  .commentsInput {
    width: 90%;

    font-size: 2.4rem;
    margin: 40px 0 40px 0;
  }

  .additionalInfoText {
    text-align: center;
    width: 80%;
    margin: auto;
  }

  .thirdAddress {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 20px;
  }
  .fieldHolder {
    position: relative;
  }
  .thirdInput {
    border: 0;
    border-bottom: 1px solid #cac6bc;
    outline: none;
    font-size: 1.5rem;
  }
  .thirdLabel {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
    display: flex;
    align-items: center;
    cursor: text;
    transition: 0.3s ease-in-out;
  }

  .thirdInput,
  .thirdLabel {
    width: 100%;
    /* height: 75px; */
    height: 4.7rem;
  }

  .thirdContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .thirdInput {
    width: 20rem;
  }

  .thirdInput:focus + label,
  .thirdInput:valid + label {
    color: black;
    font-size: 1.5rem;
    top: -1.3rem;
    pointer-events: none;
  }
  .thirdTitle {
    font-size: 2.4rem;
    padding: 0 6rem;
    text-align: center;
  }
  .thirdSubmitButtonBorder {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    width: 27rem;
  }
  .thirdSubmitButton {
    width: 25rem;
    margin: 0rem 0;
  }

  .propertyInfoContentContainer {
    height: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .fourthRow {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .fourthCol1 {
    width: 100%;
    height: 57rem;
  }

  .fourthCol2 {
    display: none;
  }
  .fourthCol2 img {
    height: 50rem;
    width: auto;
    margin: auto;
  }
  .fourthTitle {
    font-size: 2.5rem;
    color: #00305e;
    margin-bottom: 1.5rem;
    text-align: center;
    /* margin-top: -50px; */
  }
  .fourthDescriptionContainer {
    width: 70%;
    margin: 1.5rem auto 0 auto;
  }
  .fourthParagraphSection {
    padding-top: 0.1rem;
  }

  .fourthDescriptionText {
    font-size: 1.3rem;
    color: black;
    line-height: 2.2rem;
  }

  .agentInfoFooterContainer {
    /* background-color: #00305e; */
    width: 100%;
    height: 100%;
  }

  .qv4CoverPageInfo {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .agentInfoFooterCol1 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 21px;
    border-bottom: 1px solid white;
  }

  .agentInfoFooterAgentName {
    font-size: 2.4rem;
    color: white;
  }
  .agentAndPhone {
    justify-content: space-between;
  }

  .agentInfoAgentPhone {
    font-size: 2.2rem;
  }
  .agentInfoDesignationAndDreRow {
    justify-content: space-between;
  }
  .agentDesignation {
    font-size: 1.3rem;
  }
  .agentInfoFooterCol2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    justify-content: center;
  }

  .secondPageSubmitBtn {
    width: 31.4rem;
  }
  .secondPageValuationButton {
    width: 28.8rem;
  }

  .submitValuationButtonText {
    font-size: 1.3rem;
  }

  .pageTrackerContainer {
    margin-top: 0rem;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pageTrackerContainer2 {
    margin-top: 0.8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pageTracker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }
  .activeTracker {
    border-radius: 50px;
    width: 75px;
    width: 55px;
    border: 1px solid #b0aa9b;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pageTrackerNum {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 10px;
    background: #f7f0e6;
    font-size: 21px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid #cac6bc;
  }

  .activeTracker .pageTrackerNum {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 10px;
    background: #fff;
    font-size: 21px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: objektiv-mk2, sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid #cac6bc;
  }
  .pageTrackerDivider {
    height: 1px;
    width: 6rem;
    background-color: #b0aa9b;
  }
  .agentInfoFooterContainer {
    height: 100%;

    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .agentInfoFooterContainer .transparentInnerContainer {
    height: 100%;
    width: 100%;
  }

  .agentInfoFooterContainer .qv4CoverPageInfo {
    width: 100%;
  }
  .agentInfoFooterContainer .agentInfoSection {
    height: 100%;
    width: 100%;

    flex-direction: column;
  }
  .agentInfoFooterContainer .agentAndPhone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 29px;
  }

  .agentInfoFooterContainer .agentName {
    font-size: 29px;
  }
  .agentInfoFooterContainer .phoneNumRow {
    font-size: 29px;
  }
  .zechInfoFooterRow {
    flex-direction: column;
  }
  .zechInfoFooterRow .teamAgentName {
    font-size: 2.8rem;
  }
  .zechInfoFooterRow .phoneNumRow {
    font-size: 1.8rem;
  }
  .zechInfoFooterRow .teamAgentContainer {
    justify-content: center;
  }
  .zechInfoFooterRow .teamAgentAndPhone {
    height: 35px;
  }
  .agentInfoFooterContainer .designationAndDre {
    width: 30rem;

    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .agentInfoFooterContainer .dreWord,
  .agentInfoFooterContainer .dreNum {
    font-size: 17.5px;
  }
  .agentInfoFooterContainer .agentDesignation {
    font-size: 17.5px;
  }
  .agentInfoFooterContainer .agentInfoCol {
    display: flex;
    flex-direction: column;
  }
  .agentInfoFooterContainer .agentInfoBoxLogo {
    margin-top: 10px;
    padding-top: 10px;
    width: 100%;
    height: 121px;
    justify-content: center;
    border-top: 1px solid white;
  }

  .agentInfoFooterContainer .agentInfoBoxLogo img {
    max-height: 100%;
  }
  .agentInfoBoxLogo.agentLogoMr {
    margin-right: 0px;
  }
  .qv4CoverPageInfo .agentInfoBoxLogo .Horizontal_White_Compass {
    margin-top: 20px;
    height: auto;
    width: auto;
    max-height: 85%;
    max-width: 65%;
  }
  .firstHomeownerNames div {
    font-size: 4.7rem;
  }
  .firstDescriptionContainer p,
  .firstAgentName p {
    font-size: 2.7rem;
  }
  .valuationButtonText {
    font-size: 1.9rem;
  }
  .privacyDisclaimerContainer {
    margin-bottom: 1.5rem;
  }
  .privacyDisclaimerText {
    font-size: 1.5rem;
  }
  .firstCoverImg {
    height: 580px;
  }
  .transparentInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .privacyPolicy {
    margin: 1rem 0;
  }
  .agentInfoBoxLogo {
    margin-top: 20px;
  }

  .propertyInfoContentContainer2 {
    width: 88%;
  }
  .secondPageTitle {
    font-size: 3.3rem;
  }
  .secondAddress div {
    font-size: 2.5rem;
  }
  .secondPageDetails div {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .propDetailsBox {
    width: 48%;
    height: 20rem;
  }
  .propDetailsCircle {
    height: 150px;
    width: 150px;
  }
  .bubbleSelect,
  .bubbleInput {
    font-size: 3.1rem;
  }
  .propDetailsTitle {
    font-size: 2.4rem;
  }
  .additionalInfoText {
    font-size: 2rem;
  }
  .secondPageSubmitBtn {
    width: 38.4rem;
    height: 8.4rem;
  }
  .secondPageValuationButton {
    height: 6rem;
  }
  .secondPageValuationButton {
    width: 35.8rem;
  }
  .submitValuationButtonText {
    font-size: 1.8rem;
  }
  .thirdAddress {
    font-size: 2.2rem;
  }
  .thirdTitle {
    font-size: 2.8rem;
  }
  .thirdFormContainer {
    width: 600px;
  }
  .thirdInput {
    width: 33rem;
  }
  .thirdInput:focus + label,
  .thirdInput:valid + label {
    font-size: 2rem;
  }
  .thirdLabel {
    font-size: 2rem;
  }
  .thirdSubmitButtonBorder {
    width: 37rem;
  }
  .thirdSubmitButton {
    width: 34rem;
    height: 5.4rem;
  }
  .thirdSubmitButtonText {
    font-size: 2.9rem;
  }
  .fourthCol1 {
    height: 41rem;
  }
  .fourthCol2 {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 55rem;
  }
  .fourthTitle {
    font-size: 4.2rem;
  }
  .fourthDescriptionText {
    font-size: 2.3rem;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) and (max-device-width: 3000px) {
  /* Styles */

  .propertyInfoPage .pageTrackerContainer {
  }
}

/* iPhone 4 ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  /* Styles */
}
